<template>
  <b-card>
    <loading :active="isLoading" loader="bars" color="#7367f0" :is-full-page="fullPage" />
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
    </div>
    <div class="custom-search">
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>NIJ</label>
            <b-form-input v-model="nij" placeholder="NIJ" type="text" class="d-inline-block" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Name</label>
            <b-form-input v-model="name" placeholder="Name" type="text" class="d-inline-block" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Church</label>
            <v-select v-model="church" placeholder="Choose Church" label="name" :options="churchOptions" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Service Category</label>
            <v-select v-model="serviceCategory" placeholder="Choose Service Category" label="name"
              :options="serviceCategoryOptions" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Service</label>
            <v-select v-model="service" placeholder="Choose Service" label="name" :options="serviceOptions" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Ministry</label>
            <v-select v-model="ministry" placeholder="Choose Ministry" label="name" :options="ministryOptions" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Divisions</label>
            <v-select v-model="division" placeholder="Choose Division" label="name" :options="divisionOptions" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Check In Date</label>
            <v-select v-model="date" placeholder="Choose Check In Date" label="name" :options="dateOptions" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group>
            <b-button variant="primary" class="mr-1" type="button" @click="searchData()">
              Search
            </b-button>
            <vue-excel-xlsx :data="exportData" :columns="exportColumns" :file-name="'servolution'" :file-type="'xlsx'"
              :sheet-name="'sheetname'" class="btn btn-primary">
              Export
            </vue-excel-xlsx>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <vue-good-table :columns="columns" :rows="rows" style-class="vgt-table striped" :pagination-options="{
      enabled: true,
      perPageDropdownEnabled: false,
      dropdownAllowAll: false,
    }">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'check_in_at'">
          <!-- {{ new Date(new Date(props.row.created_at).getTime() + props.row.timezone * 1000) }} -->
          {{ moment(props.row.created_at).format("dddd, DD MMMM YYYY, HH:mm") }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  VBTooltip,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import Ripple from 'vue-ripple-directive'
import CryptoJS from 'crypto-js'
import * as moment from 'moment-timezone'
// import excel from 'vue-excel-export'
import _ from 'lodash'
import vSelect from 'vue-select'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    VueGoodTable,
    BButton,
    // BDropdown,
    // BDropdownItem,
    Loading,
    // excel,
    vSelect,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      moment,
      isLoading: true,
      fullPage: false,
      pageLength: 10,
      dir: false,
      nij: '',
      name: '',
      church: '',
      service: '',
      serviceCategory: '',
      ministry: '',
      division: '',
      date: '',
      churchOptions: [],
      categoryOptions: [],
      ministryOptions: [],
      divisionOptions: [],
      serviceOptions: [],
      serviceCategoryOptions: [],
      dateOptions: [],
      columns: [
        {
          label: 'NIJ',
          field: 'nij',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Ministry',
          field: 'ministry',
        },
        {
          label: 'Division',
          field: 'division',
        },
        {
          label: 'Church',
          field: 'church',
        },
        {
          label: 'Service Category',
          field: 'service_category',
        },
        {
          label: 'Service',
          field: 'service',
        },
        {
          label: 'Check-In Date',
          field: 'check_in_at',
        },
      ],
      rows: [],
      searchTerm: '',
      json_fields: {
        'Complete name': 'name',
        City: 'city',
        Telephone: 'phone.mobile',
        'Telephone 2': {
          field: 'phone.landline',
          callback: value => `Landline Phone - ${value}`,
        },
      },
      exportColumns: [
        {
          label: 'NIJ',
          field: 'nij',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Ministry',
          field: 'ministry',
        },
        {
          label: 'Division',
          field: 'division',
        },
        {
          label: 'Church',
          field: 'church',
        },
        {
          label: 'Service Category',
          field: 'service_category',
        },
        {
          label: 'Service',
          field: 'service',
        },
        {
          label: 'Check-In Date',
          field: 'check_in_at',
        },
      ],
      exportData: [],
      churches: [],
      ministries: [],
      divisions: [],
    }
  },
  mounted() {
    // Set data
    const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
    this.churches = userData[0].churches.map(elem => elem).join(',')
    this.ministries = userData[0].departments.map(elem => elem).join(',')
    this.divisions = userData[0].divisions.map(elem => elem).join(',')

    // Load
    this.loadDefaultCheckInsData()
  },
  methods: {
    loadDefaultCheckInsData() {
      // Get the data
      this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/volunteers/checkins/${this.churches}/${this.ministries}/${this.divisions}`)
        .then(response => {
          this.isLoading = false
          this.rows = response.data
          this.exportData = response.data

          // Load search default
          this.loadSearchDefaultValues(response.data)
        })
    },
    loadSearchDefaultValues(lists) {
      // Church
      const churches = _.sortBy(_.uniqBy(lists, 'church'), ['church'])
      _.each(churches, data => {
        this.churchOptions.push({ id: data.church_id, name: data.church })
      })

      // Service Category
      const serviceCategories = _.sortBy(_.uniqBy(lists, 'service_category'), ['service_category'])
      _.each(serviceCategories, data => {
        this.serviceCategoryOptions.push({ name: data.service_category })
      })

      // Service
      const services = _.sortBy(_.uniqBy(lists, 'service'), ['service'])
      _.each(services, data => {
        this.serviceOptions.push({ name: data.service })
      })

      // Ministry
      const ministries = _.sortBy(_.uniqBy(lists, 'ministry'), ['ministry'])
      _.each(ministries, data => {
        this.ministryOptions.push({ id: data.ministry_id, name: data.ministry })
      })

      // Division
      const divisions = _.sortBy(_.uniqBy(lists, 'division'), ['division'])
      _.each(divisions, data => {
        this.divisionOptions.push({ id: data.division_id, name: data.division })
      })

      // Date Week
      const uniqDates = _.uniqBy(lists, o => moment(o.created_at).format('DD MMMM YYYY'))
      // const dates = _.sortBy(_.uniqBy(lists, 'check_in_at'), ['check_in_at'])
      _.each(uniqDates, data => {
        this.dateOptions.push({ id: data.created_at, name: moment(data.created_at).format('DD MMMM YYYY') })
      })
    },
    searchData() {
      // Set search params
      const church = this.church ? this.church.id : ''
      const serviceCategory = this.serviceCategory ? this.serviceCategory.name : ''
      const service = this.service ? this.service.name : ''
      const ministry = this.ministry ? this.ministry.id : ''
      const division = this.division ? this.division.id : ''
      const date = this.date ? moment(this.date.id).format('YYYY-MM-DD') : ''

      // Get the data
      this.isLoading = true
      this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/volunteers/checkins/search/${this.churches}/${this.ministries}/${this.divisions}?nij=${this.nij}&name=${this.name}&church=${church}&service_category=${serviceCategory}&service=${service}&ministry=${ministry}&division=${division}&date=${date}`)
        .then(response => {
          this.isLoading = false
          this.rows = response.data
          this.exportData = response.data
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

table.vgt-table {
  border: none;
  font-size: 1rem;
}

.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}

table.vgt-table td {
  vertical-align: middle !important;
}

.vgt-wrap__footer {
  border: none;
}
</style>